import { postCheckSignUpToken, postSignUpUser, getCustomToken } from "./thunks"
import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  loading: false,
  error: null,
  success: false,
  successSignInToken: false,
  customToken: null,
}

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    resetSubscribeState: (state) => {
      state.loading = false
      state.error = null
      state.success = false
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(postCheckSignUpToken.pending, (state) => {
        state.loading = true
        state.error = null
        state.success = false
        state.successSignInToken = false
      })
      .addCase(postCheckSignUpToken.fulfilled, (state) => {
        state.loading = false
        state.error = null
        state.success = true
        state.successSignInToken = true
      })
      .addCase(postCheckSignUpToken.rejected, (state, { payload }) => {
        state.loading = false
        state.error = payload
        state.success = false
        state.successSignInToken = false
      })
      .addCase(postSignUpUser.pending, (state) => {
        state.loading = true
        state.error = null
        state.success = false
      })
      .addCase(postSignUpUser.fulfilled, (state) => {
        state.loading = false
        state.error = null
        state.success = true
      })
      .addCase(postSignUpUser.rejected, (state, { payload }) => {
        state.loading = false
        state.error = payload
        state.success = false
      })
      .addCase(getCustomToken.pending, (state) => {
        state.loading = true
        state.error = null
        state.success = false
      })
      .addCase(getCustomToken.fulfilled, (state, { payload })  => {
        state.loading = false
        state.error = null
        state.success = true 
        state.customToken = payload
      })
      .addCase(getCustomToken.rejected, (state, { payload }) => {
        state.loading = false
        state.error = payload
        state.success = false
      })
  },
})

export const { resetSubscribeState } = authSlice.actions

export default authSlice.reducer
