import React, { useEffect, useState, useCallback } from "react"
import "./logIn-restore.scss"
import { Login, RestorePassword, PopupWindow } from "../../../widgets"
import { useNavigate, useSearchParams } from "react-router-dom"
import useAuthData from "../../../shared/lib/hooks/auth/auth.hook"
import GetAuthData from "../../../shared/lib/hooks/auth/authSelector.hook"
import { Auth } from "../../../shared/api/axios/requests/auth/auth.service"
import { clearQueryParams } from "../../../shared/lib/utils/clear-query-params"

const LoginRestorePage = () => {
  const [currentForm, setCurrentForm] = useState(0)
  const [popupType, setPopupType] = useState(null)
  
  const nextForm = () => {
    setCurrentForm(1)
  }
  const goBack = () => {
    setCurrentForm(0)
  }
  const closePopup = () => {
    setPopupType(null)
  }

  const [searchParams] = useSearchParams();
  const token = searchParams?.get('token');
  const navigate = useNavigate();

  const { dispatchGetCustomToken } = useAuthData()
  const { error, customToken } = GetAuthData()
  const [errorLogin, setErrorLogin] = useState(null);

  useEffect(() => {
    if (token) {
      dispatchGetCustomToken(token)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token])

  
  useEffect(() => {
    if (token && (error || errorLogin)) {
      clearQueryParams()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error])

  
  useEffect(() => {
    if (customToken) {
      handleLoginByToken(customToken)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customToken])

  const handleLoginByToken = useCallback(async (customToken) => {
    try {
      const result = await Auth.loginUserByToken(customToken)
      if (result) {
        navigate("/enter-account")
      } else {

      }
    } catch (error) {
      setErrorLogin(true)
    }
  }, [])
  

  if(token && (!error && !errorLogin)) return <></>

  return (
    <div className="login-page">
      <div
        className={`login-wrapper ${popupType ? "blur-background" : ""} ${currentForm === 0 ? "background-pic-login" : "background-pic-restore"}`}
      >
        {currentForm === 0 ? (
          <Login nextForm={nextForm} />
        ) : (
          <RestorePassword goBack={goBack} showPopup={setPopupType} />
        )}
      </div>
      {popupType && (
        <>
          <div className="overlay"></div>
          <PopupWindow type={popupType} onClose={closePopup} />
        </>
      )}
    </div>
  )
}

export default LoginRestorePage
