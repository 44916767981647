import { useEffect } from "react"
import bg5 from "../../assets/BG5.webp"
import loginRestore from "../../assets/loginBG.webp"
import Prev1 from "../../assets/newsPreview/Prev1.webp"
import Prev2 from "../../assets/newsPreview/Prev2.webp"
import Prev3 from "../../assets/newsPreview/Prev3.webp"
import Prev4 from "../../assets/newsPreview/Prev4.webp"
import Prev5 from "../../assets/newsPreview/Prev5.webp"

const ImagePreloader = ({ setImgsLoaded }) => {
  useEffect(() => {
    const images = [bg5, loginRestore, Prev1, Prev2, Prev3, Prev4, Prev5]

    // Preload images
    const loadImage = (href) => {
      return new Promise((resolve, reject) => {
        const img = new Image()
        img.src = href
        img.onload = () => resolve(href)
        img.onerror = () => reject(new Error(`Failed to load image: ${href}`))
      })
    }

    Promise.all(images.map((image) => loadImage(image)))
      .then(() => setImgsLoaded(true)) // Signal that images are loaded
      .catch((err) => console.error(err))

    // Preload images using <link> tags (for better browser support)
    images.forEach((href) => {
      const link = document.createElement("link")
      link.rel = "preload"
      link.as = "image"
      link.href = href
      document.head.appendChild(link)
    })

    // Cleanup on unmount
    return () => {
      const links = document.querySelectorAll('link[rel="preload"]')
      links.forEach((link) => document.head.removeChild(link))
    }
  }, [setImgsLoaded])

  return null
}

export default ImagePreloader
