import React from "react"
import "./getAnalysis.scss"
import { InstAnalysis } from "../../../../features"
import { Icon } from "../../../../shared"

const GetAnalysis = ({ handleSubmit, handleChange, nickname, error }) => {
  return (
    <div className="get-analysis-section">
      <div className="get-analysis-block">
        <Icon
          type="instCreate"
          alt="instCreate"
          width={129}
          height={129}
          className="get-analysis-img"
        />
        <p className="title24-medium-urbanist">Instagram account</p>
        <p className="title14-regular-urbanist">
          Enter your Instagram username to get full data analysis
        </p>
        <InstAnalysis
          onClick={handleSubmit}
          nickname={nickname}
          handleChange={handleChange}
        />
        {error === "This Instagram profile is already being updated" && (
          <div className="">
            <p className="title14-semibold-urbanist">
              This Instagram profile is already being updated
            </p>
            <p className="title14-semibold-urbanist">
              Go to the analytics page to see the analysis result in a few minutes
            </p>
          </div>
        )}
        {error === "You are already updating your Instagram profile" && (
          <div className="">
            <p className="title14-semibold-urbanist">
              You are already updating your Instagram profile
            </p>
            <p className="title14-semibold-urbanist">
              Wait for the data to update
            </p>
          </div>
        )}
         {error && error.includes("You cannot update the Instagram username now.") && (
  <div className="">
    <p className="title14-semibold-urbanist">
      {error}
    </p>
  </div>
)}

        <div className="get-analysis-defence">
          <Icon type="defence" width={28} height={28} alt="icon"></Icon>
          <p className="title14-regular-urbanist defence-text">
            Your instagram account is safe. We don’t see or store your password.
            We will never action on your behalf.
          </p>
        </div>
      </div>
    </div>
  )
}

export default GetAnalysis
