import { useSelector } from "react-redux"

const GetAuthData = () => {
  const { loading, error, success, successSignInToken, customToken } = useSelector(
    (state) => state.auth
  )
  return { loading, error, success, successSignInToken, customToken }
}

export default GetAuthData
