import { useSelector } from "react-redux"

const GetInstagamData = () => {
  const { loading, error, success, profile, profileAnalysis, timer } =
    useSelector((state) => state.instagram)

  return { loading, error, success, profile, profileAnalysis, timer }
}

export default GetInstagamData
