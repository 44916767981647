import React from "react"
import { Routes, Route, Navigate, useLocation } from "react-router-dom"
import {
  EnterAccountPage,
  LoginRestorePage,
  AccountInformationPage,
  AnalyticsPage,
  InsightsPage,
  NewsPage,
  NewsArticle,
  PrivacyPolicy,
  TermsOfUse,
} from "../../pages"
import { AccountFooter, AccountHeader } from "../../widgets"
import ScrollToTop from "../../shared/lib/hooks/useScrollToTop"
import PrivateRoute from "./PrivateRoute"

export const useRoutes = () => {
  const AccountHeaderFooter = ({ element, isLogo }) => {
    const location = useLocation()
    const hasNextUpdate = location.pathname === "/account-analytics"

    return (
      <>
        <div className="flex-box">
          <AccountHeader isLogo={isLogo} />
          {element}
          <AccountFooter hasNextUpdate={hasNextUpdate} />
        </div>
      </>
    )
  }

  return (
    <>
      <ScrollToTop />
      <Routes>
      <Route path="/enter-account" exact element={<EnterAccountPage />} />
        <Route element={<PrivateRoute />}>
      
          <Route
            path="/insights"
            exact
            element={<AccountHeaderFooter element={<InsightsPage />} />}
          />
          <Route
            path="/news"
            exact
            element={<AccountHeaderFooter element={<NewsPage />} />}
          />
          <Route
            path="/news-article/:id"
            exact
            element={<AccountHeaderFooter element={<NewsArticle />} />}
          />
          <Route
            path="/account-information"
            exact
            element={
              <AccountHeaderFooter
                isLogo={true}
                element={<AccountInformationPage />}
              />
            }
          />
          <Route
            path="/account-analytics"
            exact
            element={<AccountHeaderFooter element={<AnalyticsPage />} />}
          />
        </Route>
        <Route path="/login" exact element={<LoginRestorePage />} />
        <Route
          path="/*"
          element={<Navigate replace to="/account-information" />}
        />
        <Route path="/terms-of-servise" exact element={<TermsOfUse />} />
        <Route path="/privacy-policy" exact element={<PrivacyPolicy />} />
      </Routes>
    </>
  )
}
