import React from "react"
import "./likesAndComments.scss"
import { AnalyticBlock } from "../../../../shared"

const LikesAndComments = ({ profileAnalysis }) => {
  return (
    <div className="like-comment-section">
      <p className="title20-bold-urbanist analytic-widget-title-color">
        Likes & Comments
      </p>
      <div className="like-comment-block">
        <AnalyticBlock
          text={profileAnalysis?.totalLikes ? profileAnalysis?.totalLikes : "0"}
          title="Likes"
          className="analytic-block-iconbg"
          type="likes"
          width={24}
          height={24}
          alt="likesIcon"
          strokeColor="#315DF4"
        />
        <AnalyticBlock
          text={
            profileAnalysis?.totalComments
              ? profileAnalysis?.totalComments
              : "0"
          }
          title="Comments"
          className="analytic-block-iconbg"
          type="comments"
          width={24}
          height={24}
          alt="commentsIcon"
          strokeColor="#315DF4"
        />
      </div>
    </div>
  )
}

export default LikesAndComments
