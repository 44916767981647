import React, { useEffect } from "react"
import "./accountAnalytic.scss"
import {
  LatestPosts,
  UserEngagement,
  LikesAndComments,
  MostLikedPost,
  Audience,
  NextUpdate,
} from "../../../widgets"
import { ViewInsights } from "../../../features"
import GetInstagamData from "../../../shared/lib/hooks/instagram/instagramSelector.hook"
import useInstagramData from "../../../shared/lib/hooks/instagram/instagram.hook"
import { Loader } from "../../../shared"
import { EmojiSad } from "../../../shared/lib/icons/icons"

const AccountAnalytic = () => {
  const {
    dispatchInstagramAnalysisAccount,
    dispatchGetProfileNextUpdateForCurrectUser,
  } = useInstagramData()
  const { loading, profileAnalysis, error, timer } = GetInstagamData()

  useEffect(() => {
    const fetchData = async () => {
      try {
        await dispatchGetProfileNextUpdateForCurrectUser()
        await dispatchInstagramAnalysisAccount()
      } catch (error) {
        console.error("Error:", error)
      }
    }
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  if (loading) {
    return (
      <div className="account-information-page">
        <div className="upper-gradient"></div>
        <Loader />
      </div>
    )
  }

  if (error === "ThrottlerException: Too Many Requests") {
    return (
      <div className="account-information-page">
        <div className="upper-gradient"></div>
        <div className="center-wrapper-loading">
          <Loader />
          <p className="title24-bold-urbanist">
            Server Error: Too many requests
          </p>
          <p className="title16-semibold-urbanist">Try reload page later</p>
        </div>
      </div>
    )
  }
  if (error === "This Instagram profile is already being updated") {
    return (
      <div className="account-information-page">
        <div className="upper-gradient"></div>
        <div className="center-wrapper-loading">
          <p className="title24-bold-urbanist">
            This Instagram profile is already being updated
          </p>
          <p className="title16-semibold-urbanist">
            Refresh the page in a few minutes
          </p>
        </div>
      </div>
    )
  }
  if (error === "No active subscription") {
    return (
      <div className="account-information-page">
        <div className="upper-gradient"></div>
        <div className="center-wrapper-loading">
          <img className="emoji-icon-error" src={EmojiSad} alt="" />
          <p className="title24-bold-urbanist">
            Your subscription has expired
          </p>
          <p className="title16-semibold-urbanist">
            Please update your subscription to view user data.
          </p>
        </div>
      </div>
    )
  }
  if (timer?.entryStatus === "not exists") {
    return (
      <div className="account-information-page">
        <div className="upper-gradient"></div>
        <div className="center-wrapper-loading">
          <Loader />
          <p className="title24-bold-urbanist">
            The server is updating your data.
          </p>
          <p className="title16-semibold-urbanist">
            Account details are being updated, please refresh the page in about
            few minutes to see the new details
          </p>
        </div>
      </div>
    )
  }

  return (
    <div className="analytics-wrapper">
      <div className="upper-gradient"></div>
      <div className="analytics-padding">
        <LatestPosts />
        <UserEngagement profileAnalysis={profileAnalysis} />
        <LikesAndComments profileAnalysis={profileAnalysis} />
        <MostLikedPost profileAnalysis={profileAnalysis} />
        <Audience profileAnalysis={profileAnalysis} />
        <ViewInsights
          typeIcon="insights"
          altIcon="InsightsIcon"
          btnText="View insights"
          text="We have prepared for you a number of tips and insights to improve your profile"
          link="/insights"
        />
      </div>
      <NextUpdate timer={timer} />
    </div>
  )
}

export default AccountAnalytic

// В течение нескольких минут ваши данные актуализируются сервером.
