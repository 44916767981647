import React, { useEffect } from "react"
import "./accountInformation.scss"
import { AccountCenterBlock, AccountUpperStatistic } from "../../../widgets"
import { Analytics, News, Insights, EmojiSad } from "../../../shared/lib/icons/icons"
import GetInstagamData from "../../../shared/lib/hooks/instagram/instagramSelector.hook"
import useInstagramData from "../../../shared/lib/hooks/instagram/instagram.hook"
import { Loader } from "../../../shared"

const AccountInformation = () => {
  const { loading, profile, error, timer } = GetInstagamData()
  const {
    dispatchInstagramAccount,
    dispatchGetProfileNextUpdateForCurrectUser,
  } = useInstagramData()

  useEffect(() => {
    const fetchData = async () => {
      try {
        await dispatchInstagramAccount()
        await dispatchGetProfileNextUpdateForCurrectUser()
      } catch (error) {
        console.error("Error:", error)
      }
    }
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (loading) {
    return (
      <div className="account-information-page">
        <div className="upper-gradient"></div>
        <Loader />
      </div>
    )
  }

  if (error === "ThrottlerException: Too Many Requests") {
    return (
      <div className="account-information-page">
        <div className="upper-gradient"></div>
        <div className="center-wrapper">
          <Loader />
          <p className="title24-bold-urbanist">
            Server Error: Too many requests
          </p>
          <p className="title16-semibold-urbanist">Try reload page later</p>
        </div>
      </div>
    )
  }
  if (error === "No active subscription") {
    return (
      <div className="account-information-page">
        <div className="upper-gradient"></div>
        <div className="center-wrapper-loading">
          <img className="emoji-icon-error" src={EmojiSad} alt="" />
          <p className="title24-bold-urbanist">
            Your subscription has expired
          </p>
          <p className="title16-semibold-urbanist">
            Please update your subscription to view user data.
          </p>
        </div>
      </div>
    )
  }
  if (error === "This Instagram profile is already being updated") {
    return (
      <div className="account-information-page">
        <div className="upper-gradient"></div>
        <div className="center-wrapper-loading">
          <p className="title24-bold-urbanist">
            This Instagram profile is already being updated
          </p>
          <p className="title16-semibold-urbanist">
            Refresh the page in a few minutes
          </p>
        </div>
      </div>
    )
  }

  if (timer?.entryStatus === "not exists") {
    return (
      <div className="account-information-page">
        <div className="upper-gradient"></div>
        <div className="center-wrapper-loading">
          <Loader />
          <p className="title24-bold-urbanist">
            The server is updating your data.
          </p>
          <p className="title16-semibold-urbanist">
            Account details are being updated, please refresh the page in about
            few minutes to see the new details
          </p>
        </div>
      </div>
    )
  }

  return (
    <div className="account-information-page">
      <div className="upper-gradient"></div>
      <AccountUpperStatistic profile={profile} />
      <div className="center-block-wrapper">
        <AccountCenterBlock
          background={"Analytics"}
          IconComponent={Analytics}
          bigText={"Analytics"}
          littleText={"Analysis of your account metrics and statistics"}
          link="/account-analytics"
        />
        <AccountCenterBlock
          background={"Insights"}
          IconComponent={Insights}
          bigText={"Insights"}
          littleText={"Find out how to improve your account metrics"}
          link="/insights"
        />
        <AccountCenterBlock
          background={"News"}
          IconComponent={News}
          bigText={"News"}
          littleText={"Stay up to date with current and popular trends"}
          link="/news"
        />
      </div>
    </div>
  )
}

export default AccountInformation
