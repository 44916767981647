import { useNavigate } from "react-router-dom"
import {
  getCustomToken,
  postCheckSignUpToken
} from "../../../../features/Slices/Auth/thunks"
import { useDispatch } from "react-redux"

const useAuthData = () => {
  const dispatch = useDispatch()
  const checkSignUpToken = (token) => {
    dispatch(postCheckSignUpToken({ token }))
  }

  const dispatchGetCustomToken = (token) => {
    dispatch(getCustomToken(token))

  }

  return { checkSignUpToken, dispatchGetCustomToken }
}

export default useAuthData
